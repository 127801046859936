<template>
  <div class="website__domain">
    <v-row no-gutters>
      <v-col cols="12" class="d-flex align-center justify-space-between">
        <h2 class="page-title" style="font-size: 32px;">Domains</h2>
        <div class="d-flex align-center" style="gap: 16px">
          <v-btn text outlined @click="$router.push({ name: 'website.domain.connect' })">
            <v-icon left> mdi-plus</v-icon> Connect Existing Domain</v-btn
          >
          <v-btn color="primary" depressed @click="buyNewDomainModel = true">Buy New Domain</v-btn>
        </div>
      </v-col>
      <v-col v-if="isLoading" cols="12" class="mt-3">
        <SkeletonLoaders type="card-heading, list-item-three-line, list-item-three-line" />
      </v-col>
      <v-col v-else cols="12" class="elevation-form mt-5 px-5 py-5">
        <Notification v-if="errors.length > 0" :messages="errors" type="error" />
        <div class="d-flex align-center">
          <h3>Primary domain</h3>
          <!-- <v-btn @click="primaryModal = true" class="ml-auto text-decoration-none btn-link">
            Change primary domain?
          </v-btn> -->
        </div>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Host
                </th>
                <th class="text-left">
                  Status
                </th>
                <th class="text-left">
                  Date Added
                </th>
                <!-- <th v-if="enableRef" class="text-left">
                  Checkout Domain
                </th> -->
                <th class="text-left">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in domains" :key="item.host">
                <td>{{ item.host }}</td>
                <td>
                  <span :class="checkStatus(item.connectStatus).class">
                    {{ checkStatus(item.connectStatus).text }}
                  </span>
                </td>
                <td>{{ item.created_at && datetime.format(item.created_at, 'DD-MM-YYYY hh:mm:ss') }}</td>
                <!-- <td v-if="enableRef">
                  <input
                    v-if="!(item.domainType === 'SystemDefault')"
                    type="checkbox"
                    :id="item.id"
                    :value="item.id"
                    v-model="checkedCheckouts"
                    @change="updateCheckoutDomain(item.id)"
                  />
                </td> -->
                <td>
                  <v-btn v-if="item.host.indexOf('dcomcy') < 0" @click="removeDomain(item)" class="btn-link"
                    ><v-icon small>fas fa-trash</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="mt-3">
          Traffic from all your domains redirects to this primary domain.
          <!-- <v-btn @click="disableRedirection = true" class="text-decoration-none btn-link">
            Disable redirection
          </v-btn> -->
        </div>
      </v-col>
      <!-- <v-col cols="12" class="elevation-form mt-5 px-5 py-5">
        <div style="width: 624.00px;margin: auto;">
          <p>
            Để sử dụng tên miền của riêng bạn, bạn cần thêm 2 bản ghi trong phần cài đặt DNS như sau:
          </p>
          <p>
            <b>Bản ghi A (A record)</b> trỏ đến <b>18.217.43.33</b>
            <br />
            <b>Bản ghi CNAME (CNAME record)</b> trỏ đến <b>connect.dcomcy.com.</b>
          </p>
          <img
            alt=""
            src="/admin/img/image1.png"
            style="width: 624.00px; height: 89.27px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
            title=""
          />
          <p>+ Trên cloudflare, bạn vào domain-> DNS</p>
          <p>+ Trên namecheap, bạn vào domains -> Advance DNS</p>
          <p>
            + Lưu ý: bạn cần tắt proxy (chuyển hình đám mây màu cam thành màu xám hoặc DNS Only) cho cả bản ghi A và
            CNAME
          </p>
          <img
            alt=""
            src="/admin/img/image3.png"
            style="width: 624.00px; height: 161.93px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
            title=""
          />
          <img
            alt=""
            src="/admin/img/image2.png"
            style="width: 624.00px; height: 77.73px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
            title=""
          />
        </div>
      </v-col> -->
    </v-row>

    <!-- Modal disable redirection -->
    <modal model="disableRedirection" title="Disable Redirection">
      <template slot="content">
        <div>
          <p>
            Are you sure you want to disable traffic redirection to your primary domain? This can affect how people find
            your store.
          </p>
        </div>
      </template>
    </modal>

    <!-- Modal change primary domain -->
    <modal title="Change primary domain?" model="primaryModal">
      <template slot="content">
        <div>
          <p>
            Are you sure you want to change your primary domain? This will be what your customers and search engines
            see.
          </p>
        </div>
      </template>
    </modal>
    <!-- Model buy more domain -->
    <modal title="Enter domain" model="buyNewDomainModel" title-button="Save" :callback="onBuyNewDomain">
      <template slot="content">
        <v-text-field
          autocomplete="off"
          v-model.trim="formBuyNewDomain.domains"
          :error-messages="buyNewDomainError"
          @blur="validateDomain"
          placeholder="e.g. example.com"
        ></v-text-field>
      </template>
    </modal>
  </div>
</template>
<script>
import { domainService } from '@/apis/domain.s';
import datetime from '@/helpers/datetime';
import { required } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      primaryModal: false,
      disableRedirection: false,
      isLoading: false,
      domains: [],
      errors: [],
      datetime,
      checkedCheckouts: [],
      enableRef: false,
      buyNewDomainModel: false,
      formBuyNewDomain: {
        domains: null,
      },
      buyNewDomainError: null,
      isReady: false,
    };
  },
  // validations: {
  //   formBuyNewDomain: {
  //     domains: {
  //       required,
  //       validDomain(domain) {
  //         const regex = /(?:[\w-]+\.)+[\w-]+/;
  //         return regex.test(domain);
  //       },
  //     },
  //   },
  // },
  computed: {},
  created() {
    this.loadData();
    this.checkEnableRef();
    this.buyNewDomainError = null;
    this.isReady = false;
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      try {
        const { data: listDomain } = await domainService.list();
        const { data: listPendingDomain } = await domainService.getListPendingDomain();
        if (!listDomain) localStorage.clear();
        let domains = [];
        if (listDomain && listDomain.length) {
          domains = listDomain;
          listDomain.forEach(item => {
            if (item.isCheckOutDomain === true) {
              this.checkedCheckouts.push(item.id);
            }
          });
        }
        if (listPendingDomain && listPendingDomain.length) {
          domains = [
            ...domains,
            ...listPendingDomain.map(item => {
              let domain = { ...item, id: item._id };
              delete domain._id;
              return domain;
            }),
          ];
        }
        this.domains = domains;
      } catch (error) {
        console.log(error, ' error');
        this.errors = error;
      } finally {
        this.isLoading = false;
      }
    },
    async removeDomain(item) {
      if (item.connectStatus === 0) {
        try {
          await domainService.deleteDomainPending({ domain: item.host });
          this.loadData();
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          await domainService.disConnect({ host: item.host });
          // let index = this.domains.findIndex(host => host.host == host);
          // if (item.domainType === 'Primary' || item.domainType === 'PrimaryWWW') {
          //   window.location.href = 'https://' + this.$store.state.auth.accountInfo.rDomain;
          // } else {
          //   this.loadData();
          // }
          this.loadData();
        } catch (error) {
          this.errors = error;
        }
      }
    },
    async updateCheckoutDomain(id) {
      let isCheckOut;
      if (this.checkedCheckouts.includes(id)) {
        isCheckOut = true;
      } else {
        isCheckOut = false;
      }
      try {
        await domainService.addCheckoutDomain({ domainId: id, isCheckOutDomain: isCheckOut });
      } catch (error) {
        this.error = error;
      }
    },
    async checkEnableRef() {
      try {
        const checkEnableRef = await domainService.checkEnableRef();
        if (checkEnableRef) {
          this.enableRef = checkEnableRef.data[0].enableRef;
        }
      } catch (error) {
        this.errors = error;
      }
    },
    async onBuyNewDomain() {
      this.validateDomain();
      if (this.isReady == false) return;
      if (this.formBuyNewDomain.domains) {
        const { domains } = this.formBuyNewDomain;
        try {
          this.isLoading = true;
          const { data } = await domainService.checkDomain(domains);
          if (data?.status) {
            await domainService.buyNewDomain({ domains });
            this.loadData();
            this.formBuyNewDomain.domains = null;
            this.buyNewDomainModel = false;
            this.buyNewDomainError = null;
          } else {
            this.buyNewDomainError = data.msg;
          }
        } catch (error) {
          console.log('🚀 ~ file: Index.vue:267 ~ onBuyNewDomain ~ error:', error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    checkStatus(status) {
      switch (status) {
        case 0:
          return { text: 'Pending', class: 'pending' };
        case 1:
          return { text: 'Connected', class: 'connected' };
        case 2:
          return { text: 'Rejected', class: 'rejected' };
      }
    },
    isDomain(domain) {
      if (!domain) return false;
      const regex = /(?:[\w-]+\.)+[\w-]+/;
      return regex.test(domain);
    },
    validateDomain() {
      if (this.formBuyNewDomain.domains) {
        let isValidDomain = this.isDomain(this.formBuyNewDomain.domains);
        if (!isValidDomain) {
          this.buyNewDomainError = 'Your domain is not valid';
          this.isReady = false;
        } else {
          this.buyNewDomainError = null;
          this.isReady = true;
        }
      } else {
        this.buyNewDomainError = null;
        this.isReady = false;
      }
    },
  },
};
</script>
<style lang="scss">
.website__domain {
  .connected {
    background-color: var(--v-primary-base);
    padding: 5px 10px;
    border-radius: 10px;
    color: #fff;
  }
  .disconnected {
    background-color: #ddd;
    padding: 5px 10px;
    border-radius: 10px;
  }
  .pending {
    background-color: #fb8c00;
    padding: 5px 10px;
    border-radius: 10px;
    color: #fff;
  }
  .rejected {
    background-color: #ff5252;
    padding: 5px 10px;
    border-radius: 10px;
    color: #fff;
  }
}
</style>
